import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import s from './SecureCheckout.scss';
import {ReactComponent as Lock} from '../../../../../assets/images/Lock.svg';

export enum SecureCheckoutDataHook {
  lock = 'SecureCheckoutDataHook.lock',
  text = 'SecureCheckoutDataHook.text',
}

export const SecureCheckout = () => {
  const {t} = useTranslation();
  return (
    <div className={s.secureCheckout}>
      <Lock className={s.secureCheckoutLock} data-hook={SecureCheckoutDataHook.lock} />
      <span className={s.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
        {t('cart.orderSummary.secureCheckout.label')}
      </span>
    </div>
  );
};
