import React, {useState} from 'react';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';
import s from './Coupon.scss';
import {classes as stylableClasses} from './Coupon.st.css';
import {useControllerProps} from '../../ControllerContext';
import {ApplyOffer, ErrorViewType} from '@wix/wixstores-client-common-components/dist/es/src/ApplyOffer/ApplyOffer';
import {TagIcon} from '../Icons/Tag';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react';
import {getTooltipErrorMessageOptions} from './Coupon.helper';
import {promisify} from '../../../../../domain/lib/promisify';

export enum CouponDataHook {
  CouponShowButton = 'Coupon.ShowButton',
  CouponShowButtonText = 'Coupon.ShowButtonText',
}

export const Coupon = () => {
  const {t} = useTranslation();
  const {isRTL} = useEnvironment();
  const {get: getSettings} = useSettings();
  const {cart, applyCoupon, removeCoupon, couponError, sendToggleCouponBi} = useControllerProps().cartStore;
  const initialCouponCode: string = cart.appliedCoupon?.code || '';
  const [couponCode, setCouponCode] = useState<string>(initialCouponCode);
  const [toggleInput, setToggleInput] = useState<null | boolean>(null);

  const isCouponApplied = !!cart.appliedCoupon;

  const getError = () =>
    couponError ? t(`cart.errors.coupons.${couponError.code}`, getTooltipErrorMessageOptions(couponError)) : '';

  const handleApplyCoupon = (code: string) => {
    return !!code && applyCoupon(code);
  };

  const handleRemoveCoupon = () => {
    setCouponCode('');
    return removeCoupon();
  };

  const onToggle = () => {
    const updatedToggleInput = !toggleInput;
    setToggleInput(updatedToggleInput);

    if (updatedToggleInput) {
      sendToggleCouponBi();
    }
  };

  const getToggler = () => {
    return (
      <button data-hook={CouponDataHook.CouponShowButton} className={s.textButton} type="button" onClick={onToggle}>
        <TagIcon />
        <span id="coupon-label" data-hook={CouponDataHook.CouponShowButtonText} className={s.labelWithIcon}>
          {getSettings(settingsParams.CART_ADD_PROMO_CODE_LINK)}
        </span>
      </button>
    );
  };

  return (
    <ApplyOffer
      isRTL={isRTL}
      shouldAnimate={false}
      className={s.coupon}
      stylableClasses={stylableClasses}
      value={couponCode}
      isApplied={isCouponApplied}
      successMessage={t('cart.coupon_applied')}
      errorMessage={getError()}
      errorViewType={ErrorViewType.TOOLTIP}
      isCollapsible={true}
      labelId={'coupon-label'}
      fullWidthApplyButton={false}
      placeholder={t('cart.coupon_input_placeholder')}
      applyButtonText={t('cart.coupon_apply_button_label')}
      removeButtonText={t('cart.remove_button')}
      toggler={getToggler()}
      toggleInput={toggleInput}
      onApply={promisify(handleApplyCoupon)}
      onClear={promisify(handleRemoveCoupon)}
    />
  );
};
