import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../ControllerContext';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import {Loader} from '../Loader/Loader';
import s from './OrderSummary.scss';
import {SubTotals} from './SubTotals/SubTotals';
import {CouponTotal} from './CouponTotal/CouponTotal';
import {Total} from './Total/Total';
import cx from 'classnames';

export enum OrderSummaryDataHook {
  OrderSummaryHeadline = 'OrderSummary.headline',
  SubTotalsRoot = 'SubTotals.root',
  SubTotalsValue = 'SubTotals.subtotalText',
  EstimatedTaxRoot = 'EstimatedTax.root',
  EstimatedTaxShippingDestinationWithTitle = 'EstimatedTax.shippingDestinationWithTitle',
  EstimatedTaxTaxName = 'EstimatedTax.taxName',
  EstimatedTaxEstimatedTaxPrice = 'EstimatedTax.estimatedTaxPrice',
  TotalShippingRoot = 'TotalShipping.root',
  TotalShippingShippingDestinationWithTitle = 'TotalShipping.shippingDestinationWithTitle',
  TotalShippingTitle = 'TotalShipping.title',
  TotalShippingEstimatedShipping = 'TotalShipping.estimatedShipping',
  ShippingDestinationRoot = 'ShippingDestination.shippingDestination',
  ShippingDestinationTaxRoot = 'ShippingDestination.taxShippingDestination',
  ShippingDestinationChangeRegion = 'ShippingDestination.changeRegion',
  DeliveryMethodRoot = 'DeliveryMethod.root',
  DeliveryMethodDropdown = 'DeliveryMethod.dropdown',
  DeliveryMethodDropdownDescription = 'DeliveryMethod.dropdown-description',
  DeliveryErrorRoot = 'DeliveryError.root',
  DeliveryErrorMessage = 'DeliveryError.error',
  DeliveryNotificationRoot = 'DeliveryNotification.root',
  DeliveryNotificationMessage = 'DeliveryNotification.message',
  CouponRoot = 'Coupon.root',
  CouponName = 'Coupon.name',
  CouponLabel = 'Coupon.label',
  CouponDiscountValue = 'Coupon.discountValue',
  TotalTitle = 'Total.title',
  TotalFormattedValue = 'Total.formattedValue',
  TotalCurrencyConverterDisclaimer = 'Total.currencyConverterDisclaimer',
  TotalTaxIncludedLabel = 'Total.taxIncludedLabel',
  TotalCartDisclaimer = 'Total.cartDisclaimer',
}

export const OrderSummary = () => {
  const {t} = useTranslation();
  const {shouldDisplaySubtotal, shouldDisplaySubtotalTitle} = useControllerProps().orderStore;
  const {appliedCoupon} = useControllerProps().cartStore.cart;
  const {isLoading} = useControllerProps();

  const classNames = cx(s.headline, {[s.withSubtotalTitle]: shouldDisplaySubtotalTitle});

  return (
    <div className={s.totals}>
      {isLoading && <Loader />}
      <HTMLTag type="h2" className={classNames} data-hook={OrderSummaryDataHook.OrderSummaryHeadline}>
        {t('cart.orderSummary.title')}
      </HTMLTag>
      {shouldDisplaySubtotal && <SubTotals />}
      {appliedCoupon && <CouponTotal />}
      <Total />
    </div>
  );
};
